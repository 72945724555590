<template>
	<ti-card :icon="$icons.settings">
		<template #title>Registration whitelist</template>
		<ti-form-layout>
			<template>
				<v-col cols="12" lg="6">
					<v-row>
						<v-col>
							<v-checkbox v-model="corporate.open_registration" label="Open to all" hint="This will override any settings in Domains or Emails and allow anyone to register." persistent-hint></v-checkbox>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-combobox
								v-model="corporate.corporate_allowed_domains_attributes"
								label="Allowed domains"
								multiple
								dense
								chips
								hint="Press tab to complete, avoid using commas."
							></v-combobox>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" lg="6">
					<v-row>
						<v-col cols="12">
							<v-data-table :headers="headers" :items="emailsFiltered" item-key="email" :search="search">
								<template v-slot:top>
									<v-row justifemailsAsObjecty="end">
										<v-col cols="12" lg="6">
											<v-text-field v-model="search" label="Search" prepend-icon="mdi-magnify" class="mx-4"></v-text-field>
										</v-col>
										<v-col cols="auto">
											<v-dialog v-model="newDialog" width="700">
												<template v-slot:activator="{ on, attrs }">
													<v-btn color="success" dark v-bind="attrs" v-on="on" fab>
														<v-icon>{{ $icons.new }}</v-icon>
													</v-btn>
												</template>

												<v-card>
													<v-card-title class="text-h5 grey lighten-2">Add emails</v-card-title>

													<v-card-text class="mt-5">
														<v-combobox v-model="newEmails" label="New allowed emails" multiple dense chips hint="Press tab to complete, avoid using commas."></v-combobox>
													</v-card-text>

													<v-divider></v-divider>

													<v-card-actions>
														<v-spacer></v-spacer>
														<v-btn color="success" @click="addNewEmails" :disabled="newEmails.length < 1">Add</v-btn>
													</v-card-actions>
												</v-card>
											</v-dialog>
										</v-col>
									</v-row>
								</template>
								<template v-slot:footer.prepend>
									<v-row>
										<v-col cols="auto">
											<ti-export-to-csv :json-data="emailsFiltered" :filename="`${corporate.name} whitelist`"/>
										</v-col>
									</v-row>
								</template>
								<template v-slot:item.frozen="{ item }">
									<span v-if="item.frozen">Closed</span>
									<span v-else>Open</span>
								</template>
								<template v-slot:item.actions="{ item }">
									<v-btn icon @click="removeEmailFromWhitelist(item)">
										<v-icon>{{ $icons.delete }}</v-icon>
									</v-btn>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
				</v-col>

			</template>
		</ti-form-layout>
	</ti-card>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
	name: "TiWhiteList",
	computed: {
		corporate: sync('corporates/corporate'),
		emailsFiltered(){
			return this.corporate.corporate_allowed_emails_attributes.filter(e => !e._destroy)
		}
	},
	data: () => ({
		newDialog: false,
		newEmails: [],
		search: '',
		headers: [
			{
				text: 'Email address',
				align: 'start',
				sortable: true,
				value: 'email',
			},
			{
				text: '',
				align: 'end',
				sortable: false,
				value: 'actions',
			},
		]
	}),
	methods: {
		addNewEmails(){
			// check for duplicates and add to emails array
			this.newEmails.forEach((e) => {
				const existing = this.corporate.corporate_allowed_emails_attributes.find((el) => el.email === e)
				if(!existing){
					this.corporate.corporate_allowed_emails_attributes.push({
						id: null,
						email: e,
						frozen: false,
						_destroy: false
					})
				}
			})
			// reset new emails array
			this.newEmails = []
			// close dialog
			this.newDialog = false
		},
		removeEmailFromWhitelist(e){
			if(e.id){
				const index = this.corporate.corporate_allowed_emails_attributes.indexOf(e)
				if (index > -1) {
					this.$set(this.corporate.corporate_allowed_emails_attributes[index], '_destroy', true)
				}
			}
			else {
				const index = this.corporate.corporate_allowed_emails_attributes.indexOf(e)
				if (index > -1) {
					this.corporate.corporate_allowed_emails_attributes.splice(index, 1);
				}
			}
		}
	}
}
</script>