<template>
	<v-container fluid tag="section">
		<new-portal-warning></new-portal-warning>
		<v-form ref="form" v-model="valid" lazy-validation>
			<ti-card :icon="$icons.corporate">
				<template #title><span>{{ isEdit ? 'Edit' : 'New' }}</span> Corporate</template>
				<template #title-actions-shown>
					<v-btn color="success" elevation="2" fab small class="mr-4" @click="save">
						<v-icon>{{ $icons.save }}</v-icon>
					</v-btn>
				</template>
				<template #title-actions-hidden>
					<!--					<ti-confirm @confirm="destroy" message="Are you sure you want to delete this corporate?" button-text="Delete">-->
					<!--						<v-btn color="error" elevation="2" fab small class="mr-4">-->
					<!--							<v-icon>{{ $icons.delete }}</v-icon>-->
					<!--						</v-btn>-->
					<!--					</ti-confirm>-->
				</template>

				<ti-form-layout>
					<template #sidebar>
						<v-col cols="12">
							<ti-image-upload v-model="corporate.logo_id" label="Logo" :image-id="corporate.logo_id" :key="corporate.id"></ti-image-upload>
						</v-col>

						<v-col cols="12">
							<v-text-field v-model="corporate.user_limit" type="number" label="User limit" :hint="corporateUserCount" persistent-hint></v-text-field>
						</v-col>

						<v-col cols="12" v-if="overUserLimit">
							<v-alert type="error">Over limit</v-alert>
						</v-col>

						<v-col cols="12">
							<span>If you suspect that the user invites is out of sync with registered users, you can force a sync. This will not trigger any emails to be sent.</span>
							<br>
							<v-btn color="primary" @click="sync_user_invites">Sync user invites</v-btn>
						</v-col>

						<v-col cols="12">
							<v-text-field v-model="corporate.slug" :rules="validations.required" label="Slug"></v-text-field>
						</v-col>

						<v-col cols="12">
							<v-select v-model="corporate.brand_id" :items="brands" item-text="name" item-value="id" label="Brand"></v-select>
						</v-col>

						<v-col cols="12">
							<v-select v-model="corporate.corporate_status" :items="statuses" label="Status"></v-select>
						</v-col>

						<v-col cols="12">
							<v-select v-model="corporate.landing_page" :items="['courses', 'benchmark', 'cim_certificates']" label="Landing page"></v-select>
						</v-col>
					</template>

					<template>
						<v-col cols="12" lg="6">
							<v-text-field v-model="corporate.name" :rules="validations.name" label="Name"></v-text-field>
						</v-col>

						<v-col cols="12" lg="6">
							<v-text-field v-model="corporate.short_name" :rules="validations.name" label="Short name"></v-text-field>
						</v-col>

						<v-col cols="12" lg="6">
							<v-text-field v-model="corporate.technical_contact" label="Technical contact"></v-text-field>
						</v-col>

						<v-col cols="12" lg="6">
							<v-text-field v-model="corporate.technical_email" label="Technical email"></v-text-field>
						</v-col>

						<v-col cols="12" lg="6">
							<v-text-field v-model="corporate.corporate_contact" label="Corporate contact"></v-text-field>
						</v-col>

						<v-col cols="12" lg="6">
							<v-text-field v-model="corporate.corporate_email" label="Corporate email"></v-text-field>
						</v-col>

					</template>

				</ti-form-layout>
			</ti-card>


			<!-- Additional Settings -->
			<ti-card :icon="$icons.corporate">
				<template #title>Additional options</template>
				<ti-form-layout>
					<template>
						<v-col cols="12" lg="6">
							<v-checkbox v-model="corporate.show_password_login" label="Enable Password Login" hint="Disabling this will mean users cannot log in with a password, this should only be disabled if you intend users to only use SSO for example" persistent-hint></v-checkbox>
						</v-col>

						<v-col cols="12" lg="6">
							<v-checkbox v-model="corporate.azure_sso" label="Enable Azure SSO"></v-checkbox>
						</v-col>

						<v-col cols="12" lg="6">
							<v-checkbox v-model="corporate.use_user_groups" label="Enable User Groups"></v-checkbox>
						</v-col>
					</template>
				</ti-form-layout>
			</ti-card>

			<!-- DESIGN -->
			<ti-card :icon="$icons.design">
				<template #title>Page Customization</template>
				<ti-form-layout>
					<template>
						<v-col cols="12">
							<v-textarea v-model="corporate.welcome_message" label="Welcome message"></v-textarea>
						</v-col>
						<v-col cols="12">
							<ti-editor v-model="corporate.welcome_text" label="Welcome text"></ti-editor>
						</v-col>
						<v-col cols="6">
							<v-text-field
								v-model="corporate.accent_1"
								:clearable="true"
								:value="corporate.accent_1"
								@click:clear.stop="corporate.accent_1 = null"
								label="Color"
								@click="colorModal = true"
								readonly
							></v-text-field>
							<v-dialog v-model="colorModal" persistent max-width="300px">
								<v-card>
									<v-card-title>
										<span class="headline">Color Picker</span>
									</v-card-title>
									<v-card-text>
										<v-color-picker
											v-model="corporate.accent_1"
											mode="hexa"
											@update:color="(value) => corporate.accent_1 = value.hex"
										></v-color-picker>
									</v-card-text>
									<v-card-actions>
										<v-btn color="primary" outlined @click="colorModal = false">Close</v-btn>
										<v-spacer></v-spacer>
										<v-btn color="primary" @click="colorModal = false">Done</v-btn>
									</v-card-actions>
								</v-card>
							</v-dialog>
						</v-col>
						<v-col cols="6"></v-col>
						<v-col cols="12" lg="6">
							<v-text-field v-model="corporate.body_font" label="Font"></v-text-field>
						</v-col>

						<v-col cols="12" lg="6">
							<v-text-field v-model="corporate.body_font_source" label="Font source"></v-text-field>
						</v-col>

						<v-col cols="12" lg="6">
							<v-checkbox v-model="corporate.hide_shapes" label="Hide shapes"></v-checkbox>
						</v-col>

					</template>
				</ti-form-layout>
			</ti-card>

			<!-- FILTERS -->
			<ti-card :icon="$icons.filter">
				<template #title>Benchmark Filters</template>
				<ti-form-layout>
					<template>
						<v-col cols="12" lg="6">
							<v-select multiple :items="user_key_focuses" label="Key focus" item-value="name" item-text="name" v-model="corporate.filter_key_focus"></v-select>
						</v-col>
						<v-col cols="12" lg="6">
							<v-select multiple :items="user_industries" label="Industry" item-value="name" item-text="name" v-model="corporate.filter_industry"></v-select>
						</v-col>
						<v-col cols="12" lg="6">
							<v-select multiple :items="user_seniorities" label="Seniority" item-value="name" item-text="name" v-model="corporate.filter_seniority"></v-select>
						</v-col>
						<v-col cols="12">
							<h3>Benchmark comparison report filters</h3>
						</v-col>
						<v-col cols="12" lg="6">
							<v-select multiple :items="benchmark_comparison_report_filters" label="Benchmark comparison report filters" item-value="value" item-text="text" v-model="corporate.benchmark_report_filters"></v-select>
						</v-col>
					</template>
				</ti-form-layout>
			</ti-card>

			<!-- TOOLKIT -->
			<ti-card :icon="$icons.tools">
				<template #title>Toolkit</template>
				<ti-form-layout>
					<template>
						<v-col cols="12" lg="6">
							<v-checkbox v-model="corporate.show_toolkit" label="Show toolkit"></v-checkbox>
						</v-col>
						<v-col cols="12" lg="6">
							<v-checkbox v-model="corporate.use_ti_toolkit" label="Use Target Internet Toolkit"></v-checkbox>
						</v-col>
						<v-col cols="12" v-for="(document, index) in corporate.documents_attributes">
							<v-row v-if="!document._destroy">
								<v-col>
									<ti-document-upload label="Document" v-model="corporate.documents_attributes[index]"></ti-document-upload>
								</v-col>
								<v-col cols="auto">
									<v-btn color="error" elevation="2" fab small class="mr-4" @click="deleteDocument">
										<v-icon>{{ $icons.delete }}</v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</v-col>

						<v-col>
							<v-btn color="primary" elevation="2" fab small class="mr-4" @click="addDocument">
								<v-icon>{{ $icons.plus }}</v-icon>
							</v-btn>
						</v-col>
					</template>
				</ti-form-layout>
			</ti-card>

			<!-- FEATURES  -->
			<ti-card :icon="$icons.cog">
				<template #title>Features</template>
				<ti-form-layout>
					<template>
						<v-col cols="12" lg="6">
							<v-checkbox v-model="corporate.show_suggested_content" label="Show suggested content"></v-checkbox>
						</v-col>
						<v-col cols="12" lg="6">
							<v-checkbox v-model="corporate.show_new_resources" label="Show recently added"></v-checkbox>
						</v-col>
						<v-col cols="12" lg="6">
							<v-checkbox v-model="corporate.show_updated_resources" label="Show recently updated"></v-checkbox>
						</v-col>
						<v-col cols="12" lg="6">
							<v-checkbox v-model="corporate.show_courses" label="Show Courses menu"></v-checkbox>
						</v-col>
						<v-col cols="12" lg="6">
							<v-checkbox v-model="corporate.show_resources" label="Show Resources menu"></v-checkbox>
						</v-col>
						<v-col cols="12" lg="6">
							<v-checkbox v-model="corporate.show_learning_hub_resources" label="Show Learning Hub Resources"></v-checkbox>
						</v-col>
						<v-col cols="12" lg="6">
							<v-checkbox v-model="corporate.show_benchmark" label="Show Benchmark menu"></v-checkbox>
						</v-col>
						<v-col cols="12" lg="6">
							<v-checkbox v-model="corporate.show_cim_certification" label="Show Certification menu"></v-checkbox>
						</v-col>
						<v-col cols="12" lg="6">
							<v-checkbox v-model="corporate.show_cim_qualification" label="Show Qualification menu"></v-checkbox>
						</v-col>
						<v-col cols="12" lg="6">
							<v-checkbox v-model="corporate.show_reports" label="Show Reports menu"></v-checkbox>
						</v-col>
						<v-col cols="12" lg="6">
							<v-checkbox v-model="corporate.show_corporate_reports" label="Show Corporate Reports menu"></v-checkbox>
						</v-col>
						<v-col cols="12" lg="6">
							<v-select
								v-model="corporate.filter_resource_types"
								label="Filter resource types"
								multiple
								:items="['Course', 'Article', 'Video', 'Podcast']"
								dense
								chips
								persistent-hint
								hint="Leave blank for all"
							></v-select>
						</v-col>
					</template>
				</ti-form-layout>
			</ti-card>

			<!-- CERTIFICATES  -->
			<ti-card :icon="$icons.certificate">
				<template #title>Certificates</template>
				<v-data-table :headers="certificatesHeaders" :items="certificates_filtered" item-key="id" :search="usersSearch">
					<template v-slot:top>
						<v-row justify="end">
							<v-col cols="12" lg="6">
								<v-select ref="certificate_add_select" clearable :items="certificates" item-text="name" item-value="id" @change="addCertificate" return-object label="Add certification"></v-select>
							</v-col>
							<v-col cols="12" lg="6">
								<v-text-field
									v-model="usersSearch"
									label="Search"
									prepend-icon="mdi-magnify"
									class="mx-4"
								></v-text-field>
							</v-col>
						</v-row>
					</template>
					<template v-slot:item.actions="{ item }">
						<v-btn icon @click="removeCertificate(item)">
							<v-icon>{{ $icons.delete }}</v-icon>
						</v-btn>
					</template>
				</v-data-table>
			</ti-card>

			<!-- CUSTOM FIELDS -->
			<ti-card :icon="$icons.settings">
				<template #title>Custom Fields</template>
				<ti-form-layout>
					<template>
						<v-col cols="12" v-for="(customField, index) in customFields">
							<v-row v-if="!customField._destroy">
								<v-col cols="12" lg="auto">
									<v-btn color="secondary" elevation="2" fab small class="mr-4" @click="moveCustomFieldUp(index)">
										<v-icon>{{ $icons.up }}</v-icon>
									</v-btn>
									<v-btn color="secondary" elevation="2" fab small class="mr-4" @click="moveCustomFieldDown(index)">
										<v-icon>{{ $icons.down }}</v-icon>
									</v-btn>
								</v-col>
								<v-col cols="12" lg="3">
									<v-text-field v-model="customField.field_name" label="Field label"></v-text-field>
								</v-col>

								<v-col cols="12" lg="3">
									<v-select v-model="customField.field_type" :items="customFieldTypes" item-text="label" item-value="value" label="Field type"></v-select>
								</v-col>

								<v-col cols="12" lg="auto">
									<v-checkbox v-model="customField.display_on_registration" label="On registration"></v-checkbox>
								</v-col>

								<v-col cols="12" lg="auto">
									<v-btn color="error" elevation="2" fab small class="mr-4" @click="deleteCustomField(index)">
										<v-icon>{{ $icons.delete }}</v-icon>
									</v-btn>
									<v-btn color="secondary" elevation="2" fab small class="mr-4" @click="openCustomFieldSettingsDialog(customField)" v-if="customField.field_type === 'select'">
										<v-icon>{{ $icons.cog }}</v-icon>
									</v-btn>
								</v-col>

							</v-row>

						</v-col>

						<v-col cols="12">
							<v-btn color="primary" elevation="2" fab small class="mr-4" @click="addCustomField">
								<v-icon>{{ $icons.plus }}</v-icon>
							</v-btn>
						</v-col>
					</template>

					<template #sidebar>
						<v-card v-if="customFieldDialogItem" flat>
							<v-card-title class="text-h5">Options for select: {{ customFieldDialogItem.field_name }}</v-card-title>
							<v-card-text class="mt-5">
								<v-row>
									<v-col cols="12">
										<p>Note, if you remove or edit an option this will not effect users that have already registered and chosen that option.</p>
									</v-col>
								</v-row>
								<v-row v-for="(option, index) in customFieldDialogItem.options">
									<v-col cols="10">
										<v-text-field v-model="customFieldDialogItem.options[index]" label="Option text"></v-text-field>
									</v-col>
									<v-col cols="2">
										<v-btn color="error" elevation="2" fab small class="mr-4" @click="deleteCustomFieldOption(customFieldDialogItem, index)">
											<v-icon>{{ $icons.delete }}</v-icon>
										</v-btn>
									</v-col>
								</v-row>
							</v-card-text>
							<v-divider></v-divider>
							<v-card-actions>
								<v-btn color="success" elevation="2" fab small class="mr-4" @click="addCustomFieldOption(customFieldDialogItem)">
									<v-icon>{{ $icons.plus }}</v-icon>
								</v-btn>
								<v-spacer></v-spacer>
								<v-btn color="primary" @click="closeCustomFieldSettingsDialog">Done</v-btn>
							</v-card-actions>
						</v-card>
					</template>
				</ti-form-layout>
			</ti-card>

			<!-- EXPERTISE ALIASES -->
			<ti-card :icon="$icons.cog">
				<template #title>Expertise labels</template>
				<ti-form-layout>
					<template>
						<v-col cols="12" lg="6">
							<v-text-field v-model="corporate.expertise_1_alias" label="Foundation"></v-text-field>
						</v-col>
						<v-col cols="12" lg="6">
							<v-text-field v-model="corporate.expertise_2_alias" label="Intermediate"></v-text-field>
						</v-col>
						<v-col cols="12" lg="6">
							<v-text-field v-model="corporate.expertise_3_alias" label="Specialist"></v-text-field>
						</v-col>
					</template>
				</ti-form-layout>
			</ti-card>

			<!-- WHITELIST REGISTRATION OPTIONS -->
			<ti-white-list/>

			<!-- USERS-->
			<ti-card :icon="$icons.users" v-if="id != 1">
				<template #title>Corporate Users</template>

				<v-data-table :headers="usersHeaders" :items="corporate.users" item-key="id" :search="usersSearch">
					<template v-slot:top>
						<v-row justify="end">

							<v-col cols="12" lg="6">
								<v-text-field
									v-model="usersSearch"
									label="Search"
									prepend-icon="mdi-magnify"
									class="mx-4"
								></v-text-field>
							</v-col>
						</v-row>
					</template>
					<template v-slot:footer.prepend>
						<v-row>
							<v-col cols="auto">
								<ti-export-to-csv :json-data="corporate.users" :filename="`users`"/>
							</v-col>
						</v-row>
					</template>
					<template v-slot:item.actions="{ item }">

						<v-tooltip left v-if="item.corporate_frozen">
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon @click="unfreezeUser(item.id)" v-bind="attrs" v-on="on">
									<v-icon>{{ $icons.user_activate }}</v-icon>
								</v-btn>
							</template>
							<span>Unfreeze user's account</span>
						</v-tooltip>

						<v-tooltip left v-else>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon @click="freezeUser(item.id)" v-bind="attrs" v-on="on">
									<v-icon>{{ $icons.user_cancel }}</v-icon>
								</v-btn>
							</template>
							<span>Freeze user's account</span>
						</v-tooltip>

						<v-btn icon @click="editUser(item.id)">
							<v-icon>{{ $icons.edit }}</v-icon>
						</v-btn>
					</template>

				</v-data-table>
			</ti-card>

			<!-- USER INVITES-->
			<ti-corporate-invites/>

			<!-- USER GROUPS -->
			<ti-card :icon="$icons.user_groups" v-if="corporate.use_user_groups">
				<template #title>User groups</template>
				<ti-form-layout>
					<template>
						<v-col cols="12" lg="6">
							<v-data-table
								:headers="userGroupsHeaders"
								:items="corporate.user_groups_attributes"
								item-key="id"
								return-object
								:items-per-page="10"
								@click:row="selectUserGroup"
							>
							</v-data-table>
							<v-text-field label="New user group" v-model="newUserGroup"></v-text-field>
							<v-btn color="primary" @click="addNewUSerGroup">Add</v-btn>
						</v-col>
						<v-col cols="12" lg="6" v-if="selectedUserGroupId">
							<v-text-field v-model="selectedUserGroup.name" label="name"></v-text-field>
							<v-select
								multiple
								:items="certificates_filtered"
								item-value="certification_id"
								item-text="name"
								return-object
								label="Certificates"
								v-model="user_group_certificates"
								chips
							></v-select>
						</v-col>
					</template>
				</ti-form-layout>
			</ti-card>

		</v-form>
	</v-container>
</template>
<script>

import validationRules from "@/util/validation";
import {call, sync} from "vuex-pathify";
import models from '@/models'
import TiPillCombo from "../../components/TiPillCombo";
import TiDocumentUpload from "../../components/TiDocumentUpload";
import TiWhiteList from "./components/TiWhiteList";
import TiCorporateInvites from "./components/TiCorporateInvites";
import _orderBy from "lodash/orderBy";
import _uniqBy from "lodash/uniqBy";

export default {
	name: "CorporatesEdit",
	components: {TiPillCombo, TiDocumentUpload, TiWhiteList, TiCorporateInvites},
	props: ['id'],
	computed: {
		corporate: sync('corporates/corporate'),
		brands: sync('brands/brands'),
		certificates: sync('certificates/certificates'),
		user_key_focuses: sync('user_key_focuses/user_key_focuses'),
		user_industries: sync('user_industries/user_industries'),
		user_seniorities: sync('user_seniorities/user_seniorities'),
		user_group_certificates: {
			get() {
				return this.selectedUserGroup.user_group_certifications_attributes
			},
			set(value) {
				console.log(value)
				this.selectedUserGroup.user_group_certifications_attributes = value.map(v => {
					return {
						certification_id: v.certification_id,
						user_group_id: this.selectedUserGroup.id
					}
				})
			}
		},
		isEdit() {
			return this.$route.meta.edit
		},
		corporateUserCount() {
			return `Current user count: ${this.corporate.corporate_user_invites_attributes.length}`
		},
		customFields: {
			get() {
				return _orderBy(this.corporate.corporate_custom_fields_attributes, ['order'])
			},
			set(values) {
				this.corporate.corporate_custom_fields_attributes = values
			}
		},
		accent1: {
			get() {
				return this.corporate.accent1
			},
			set(value) {
				this.corporate.accent1 = value
			}
		},
		overUserLimit() {
			return this.corporate.user_limit > 0 && this.corporate.corporate_user_invites_attributes.length >= this.corporate.user_limit
		},
		certificates_filtered() {
			return this.corporate?.corporate_certifications_attributes?.filter(item => !item._destroy)
		},
		selectedUserGroup() {
			if (this.selectedUserGroupId) {
				return this.corporate.user_groups_attributes.find(item => item.id === this.selectedUserGroupId)
			} else {
				return {}
			}
		},
	},
	data: () => ({
		newUserGroup: '',
		valid: false,
		statuses: ['Trial', 'Active', 'Closed'],
		showPassword: false,
		colorModal: false,
		validations: {
			name: validationRules('First name', ['required', 'betweenLength:2:50']),
			required: validationRules('Field', ['required']),
		},
		usersSearch: '',
		usersHeaders: [
			{
				text: 'First name',
				align: 'start',
				sortable: true,
				value: 'first_name',
			},
			{
				text: 'Last name',
				align: 'start',
				sortable: true,
				value: 'last_name',
			},
			{
				text: 'Email',
				align: 'start',
				sortable: true,
				value: 'email',
			},
			{
				text: 'Admin',
				align: 'start',
				sortable: true,
				value: 'is_corporate_admin',
			},
			{
				text: 'Frozen',
				align: 'start',
				sortable: true,
				value: 'corporate_frozen',
			},
			{
				text: '',
				align: 'end',
				sortable: false,
				value: 'actions',
			},
		],
		customFieldTypes: [
			{
				label: 'Text',
				value: 'text'
			},
			{
				label: 'Number',
				value: 'number'
			},
			{
				label: 'Select',
				value: 'select'
			}
		],
		customFieldSettingsDialog: false,
		customFieldDialogItem: null,
		isSelecting: false,
		certificatesHeaders: [
			{
				text: 'Brand',
				align: 'start',
				sortable: true,
				value: 'brand',
			},
			{
				text: 'Type',
				align: 'start',
				sortable: true,
				value: 'type',
			},
			{
				text: 'Name',
				align: 'start',
				sortable: true,
				value: 'name',
			},
			{
				text: '',
				align: 'end',
				sortable: false,
				value: 'actions',
			},
		],
		sync_user_invites_loading: false,
		benchmark_comparison_report_filters: [
			{
				text: 'Seniority',
				value: 'seniority',
			},
			{
				text: 'Key focus',
				value: 'key_focus',
			},
			{
				text: 'Organisation',
				value: 'organization',
			},
			{
				text: 'Department',
				value: 'department',
			},
			{
				text: 'Country',
				value: 'country',
			},
		],
		userGroupsHeaders: [
			{
				text: 'Name',
				align: 'start',
				sortable: true,
				value: 'name',
			},
			{
				text: '',
				align: 'end',
				sortable: false,
				value: 'actions',
			},
		],
		selectedUserGroupId: null,
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		addNewUSerGroup() {
			if (this.newUserGroup) {
				this.corporate.user_groups_attributes.push({
					name: this.newUserGroup,
				});
				this.newUserGroup = '';
			}
		},
		selectUserGroup(item) {
			this.selectedUserGroupId = item.id;
		},
		getUserKeyFocuses: call('user_key_focuses/getUserKeyFocuses'),
		getUserIndustries: call('user_industries/getUserIndustries'),
		getUserSeniorities: call('user_seniorities/getUserSeniorities'),
		init() {
			// reset to blank corporate to avoid showing previously loaded data
			this.corporate = models.Corporate()
			this.$store.set('brands/getBrands!', null)
			this.$store.set('certificates/getCertificates!', null)
			this.getUserKeyFocuses(null);
			this.getUserIndustries(null);
			this.getUserSeniorities(null);
			if (this.isEdit) {
				this.$store.set('corporates/getCorporate!', this.id)
			} else {
				this.corporate = models.Corporate()
			}
		},
		save() {
			if (this.$refs.form.validate()) {
				if (this.isEdit) {
					this.$api.corporates.update(this.id, this.corporate)
						.then(response => {
							this.$toast.add('Corporate updated successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'Corporates'})
						})
						.catch(error => {
							console.log(error.response);
							this.$toast.handleResponseErrors(this, error)
						})
				} else {
					this.$api.corporates.create(this.corporate)
						.then(response => {
							this.$toast.add('Corporate created successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'Corporates'})
						})
						.catch(error => {
							console.log(error.response);
							this.$toast.handleResponseErrors(this, error)
						})
				}
			} else {
				this.$toast.add('Please fix validation errors', 'error')
			}
		},
		destroy() {
			alert('delete method not yet implemented.')
		},
		updateAccent1(value) {
			this.corporate.accent_1 = value.hex
		},
		addCustomField() {
			this.corporate.corporate_custom_fields_attributes.push({
				id: null,
				field_name: null,
				field_type: null,
				required: false,
				options: [""],
				display_on_registration: false
			})
		},
		deleteCustomField(index) {
			this.corporate.corporate_custom_fields_attributes[index]['_destroy'] = true
		},
		moveCustomFieldUp(index) {
			if (index < 1) {
				return
			} else {
				let item = this.corporate.corporate_custom_fields_attributes[index];
				this.corporate.corporate_custom_fields_attributes.splice(index, 1);
				this.corporate.corporate_custom_fields_attributes.splice(index - 1, 0, item);
			}
			this.corporate.corporate_custom_fields_attributes.forEach((customField, _index) => customField.order = _index)
		},
		moveCustomFieldDown(index) {
			if (index >= this.corporate.corporate_custom_fields_attributes.length) {
				return
			} else {
				let item = this.corporate.corporate_custom_fields_attributes[index];
				this.corporate.corporate_custom_fields_attributes.splice(index, 1);
				this.corporate.corporate_custom_fields_attributes.splice(index + 1, 0, item);
			}
			this.corporate.corporate_custom_fields_attributes.forEach((customField, _index) => customField.order = _index)
		},
		openCustomFieldSettingsDialog(customField) {
			this.customFieldDialogItem = customField
			// this.customFieldSettingsDialog = true
		},
		closeCustomFieldSettingsDialog() {
			this.customFieldDialogItem = null
			// this.customFieldSettingsDialog = false
		},
		addCustomFieldOption(customField) {
			customField.options.push('')
		},
		deleteCustomFieldOption(customField, index) {
			customField.options.splice(index, 1);
		},
		deleteDocument() {
			return true
		},
		addDocument() {
			this.corporate.documents_attributes.push(models.Document())
		},
		editUser(user_id) {
			this.$router.push({name: 'UsersEdit', params: {id: user_id}})
		},
		addCertificate(item) {
			this.corporate.corporate_certifications_attributes.push({
				id: null,
				certification_id: item.id,
				brand: item.brand,
				type: item.type,
				name: item.name
			})
			this.$refs.certificate_add_select.reset();
		},
		removeCertificate(item) {
			let index = this.corporate.corporate_certifications_attributes.indexOf(item)
			if (item.id) {
				this.$set(this.corporate.corporate_certifications_attributes, index, {...item, '_destroy': true})
			} else {
				this.corporate.corporate_certifications_attributes.splice(index, 1)
			}
		},
		freezeUser(user_id) {
			this.$api.users.update(user_id, {corporate_frozen: true})
				.then(response => {
					this.$toast.add('User frozen')
					this.corporate.users.find((x) => x.id === user_id).corporate_frozen = true
				})
				.catch(error => {
					console.log(error);
					this.$toast.add('Something went wrong', 'error')
				})
		},
		unfreezeUser(user_id) {
			this.$api.users.update(user_id, {corporate_frozen: false})
				.then(response => {
					this.$toast.add('User unfrozen', 'success')
					this.corporate.users.find((x) => x.id === user_id).corporate_frozen = false
				})
				.catch(error => {
					console.log(error);
					this.$toast.add('Something went wrong', 'error')
				})
		},
		sync_user_invites() {
			this.sync_user_invites_loading = true
			this.$api.corporates.sync_user_invites(this.id)
				.then(response => this.$toast.add('syncing complete'))
				.catch(error => console.log(error))
				.finally(() => this.sync_user_invites_loading = false)
		}
	}
}
</script>