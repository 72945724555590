var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ti-card',{attrs:{"icon":_vm.$icons.settings},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Registration whitelist")]},proxy:true}])},[_c('ti-form-layout',[[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"label":"Open to all","hint":"This will override any settings in Domains or Emails and allow anyone to register.","persistent-hint":""},model:{value:(_vm.corporate.open_registration),callback:function ($$v) {_vm.$set(_vm.corporate, "open_registration", $$v)},expression:"corporate.open_registration"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{attrs:{"label":"Allowed domains","multiple":"","dense":"","chips":"","hint":"Press tab to complete, avoid using commas."},model:{value:(_vm.corporate.corporate_allowed_domains_attributes),callback:function ($$v) {_vm.$set(_vm.corporate, "corporate_allowed_domains_attributes", $$v)},expression:"corporate.corporate_allowed_domains_attributes"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.emailsFiltered,"item-key":"email","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"justifemailsAsObjecty":"end"}},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search","prepend-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-dialog',{attrs:{"width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.new))])],1)]}}]),model:{value:(_vm.newDialog),callback:function ($$v) {_vm.newDialog=$$v},expression:"newDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v("Add emails")]),_c('v-card-text',{staticClass:"mt-5"},[_c('v-combobox',{attrs:{"label":"New allowed emails","multiple":"","dense":"","chips":"","hint":"Press tab to complete, avoid using commas."},model:{value:(_vm.newEmails),callback:function ($$v) {_vm.newEmails=$$v},expression:"newEmails"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","disabled":_vm.newEmails.length < 1},on:{"click":_vm.addNewEmails}},[_vm._v("Add")])],1)],1)],1)],1)],1)]},proxy:true},{key:"footer.prepend",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('ti-export-to-csv',{attrs:{"json-data":_vm.emailsFiltered,"filename":((_vm.corporate.name) + " whitelist")}})],1)],1)]},proxy:true},{key:"item.frozen",fn:function(ref){
var item = ref.item;
return [(item.frozen)?_c('span',[_vm._v("Closed")]):_c('span',[_vm._v("Open")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeEmailFromWhitelist(item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.delete))])],1)]}}])})],1)],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }