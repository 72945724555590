<template>
	<ti-card :icon="$icons.user_invites" v-if="corporate.id !== 1">
		<template #title>Invitations</template>
		<v-row>
			<v-col cols="12" lg="6">
				<v-row>
					<v-col cols="12">
						<v-text-field v-model="corporate.invite_subject" label="Invite email subject"></v-text-field>
					</v-col>
					<v-col cols="12">
						<ti-editor v-model="corporate.invite_text" label="Invite email message"></ti-editor>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" lg="6">
				<v-card elevation="2">
					<v-card-text>
						<v-row class="py-3">
							<v-col cols="12">
								<p>To: { user_email }</p>
								<p>Subject: {{ corporate.invite_subject }}</p>
							</v-col>
							<v-col cols="12" class="mt-4">
								<p>Dear { first_name }</p>
								<div v-html="corporate.invite_text"></div>
							</v-col>
							<v-col cols="12" class="mt-4 text-center">
								<v-btn color="primary">Accept</v-btn>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12" lg="6">
				<ti-card>
					<h4>Invite users</h4>
					<v-row>
						<v-col cols="12">
							<v-text-field v-model="newUserInvite.email" label="Email"></v-text-field>
							<v-text-field v-model="newUserInvite.first_name" label="First name"></v-text-field>
							<v-text-field v-model="newUserInvite.last_name" label="Last name"></v-text-field>
						</v-col>
						<v-col cols="auto">
							<v-btn @click="addNewUserInvite" color="primary">ADD INDIVIDUAL</v-btn>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols>
							<v-btn color="secondary" @click="handleFileImport" :loading="isSelecting">
								UPLOAD A .CSV
								<v-spacer></v-spacer>
								<v-icon>{{ $icons.upload }}</v-icon>
							</v-btn>
							<input ref="uploader" class="d-none" type="file" @change="onFileChanged">
						</v-col>
					</v-row>
				</ti-card>
			</v-col>

			<v-col cols="12">
				<ti-card>
					<v-data-table :headers="userInvitesHeaders" :items="invites" item-key="id" :search="userInvitesSearch">
						<template v-slot:top>
							<v-row justify="end">
								<v-col cols="12" lg="6">
									<v-text-field
										v-model="userInvitesSearch"
										label="Search"
										prepend-icon="mdi-magnify"
										class="mx-4"
									></v-text-field>
								</v-col>
							</v-row>
						</template>
						<template v-slot:footer.prepend>
							<v-row>
								<v-col cols="auto">
									<ti-export-to-csv :json-data="invites" :filename="`${corporate.name} invites`"/>
								</v-col>
							</v-row>
						</template>
						<template v-slot:item.created_at="{ item }">
							{{ item.created_at | date_to_human }}
						</template>
						<template v-slot:item.actions="{ item }">
							<v-btn icon @click="removeUserInvite(item)">
								<v-icon>{{ $icons.delete }}</v-icon>
							</v-btn>
						</template>

					</v-data-table>
				</ti-card>
			</v-col>
		</v-row>
	</ti-card>
</template>

<script>
import {sync} from "vuex-pathify";
import _uniqBy from "lodash/uniqBy";

export default {
	name: "TiCorporateInvites",
	computed: {
		corporate: sync('corporates/corporate'),
		invites() {
			return this.corporate?.corporate_user_invites_attributes?.filter(item => !item._destroy)
		},
		existingUserEmails() {
			return this.corporate.users.map(user => user.email)
		}
	},
	data: () => ({
		isSelecting: false,
		userInvitesSearch: '',
		userInvitesHeaders: [
			{
				text: 'Email',
				align: 'start',
				sortable: true,
				value: 'email',
			},
			{
				text: 'First name',
				align: 'start',
				sortable: true,
				value: 'first_name',
			},
			{
				text: 'Last name',
				align: 'start',
				sortable: true,
				value: 'last_name',
			},
			{
				text: 'Status',
				align: 'start',
				sortable: true,
				value: 'status',
			},
			{
				text: 'Created at',
				align: 'start',
				sortable: true,
				value: 'created_at',
			},
			{
				text: 'Actions',
				align: 'end',
				sortable: false,
				value: 'actions',
			},
		],
		newUserInvite: {
			email: null,
			first_name: null,
			last_name: null,
		},
	}),
	methods: {
		addNewUserInvite() {
			// Check if user already exists and what status they are in (frozen / unfrozen)
			let userExists = this.existingUserEmails.includes(this.newUserInvite.email);
			if(userExists){
				let user = this.corporate.users.filter(u => u.email === this.newUserInvite.email)[0]
				let status = user.corporate_frozen ? 'Frozen' : 'Active';
				this.$toast.add(`${this.newUserInvite.email} already exists and is ${status}`)
			}
			else {
				this.corporate.corporate_user_invites_attributes.push(this.newUserInvite);
				this.newUserInvite = {email: null, first_name: null, last_name: null}
			}
		},
		removeUserInvite(item) {
			let index = this.corporate.corporate_user_invites_attributes.indexOf(item)
			if (item.id) {
				this.$set(this.corporate.corporate_user_invites_attributes, index, {...item, '_destroy': true})
			} else {
				this.corporate.corporate_user_invites_attributes.splice(index, 1)
			}
		},
		handleFileImport() {
			this.isSelecting = true;

			// After obtaining the focus when closing the FilePicker, return the button state to normal
			window.addEventListener('focus', () => {
				this.isSelecting = false
			}, {once: true});

			// Trigger click on the FileInput
			this.$refs.uploader.click();
		},
		onFileChanged(e) {
			this.selectedFile = e.target.files[0];
			if (this.selectedFile !== null) {
				let reader = new FileReader();
				reader.onload = (e) => {
					this.corporate.corporate_user_invites_attributes = [...this.corporate.corporate_user_invites_attributes, ...this.csvToJson(e.target.result)];
					this.inviteListRemoveDuplicates();
					this.selectedFile = null;
					this.$refs.uploader.value = null;
				};
				reader.readAsText(this.selectedFile)

			}
		},
		csvToJson(csv) {
			let lines = csv.split('\r\n')
			let header = lines[0].split(',')

			lines = lines.slice(1).map(line => {
				let fields = line.split(',')
				return {...Object.fromEntries(header.map((h, i) => [h, fields[i]])), status: 'Not sent'}
			})
			console.log(lines);
			return lines
		},
		inviteListRemoveDuplicates() {
			this.corporate.corporate_user_invites_attributes = _uniqBy(this.corporate.corporate_user_invites_attributes, 'email')
		},
	}
}
</script>